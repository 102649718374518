import { ICurrentUserService } from "../User/currentUser.service";
import {
  Subscription,
  SubscriptionFrequency,
  SubscriptionType
} from "./subscription";
import * as angular from "angular";
import * as _ from "underscore";
import { ILocalizedNotificationService } from "../Services/localized.notification.service";
import { DocumentVersionSubscriptionService } from "./../Documents/DocumentVersionSubscriptions/documentVersionSubscription.service";
import { DocumentVersionSubscription } from "../Documents/DocumentVersionSubscriptions/documentVersionSubscription";
import { DocumentFamilySubscriptionService } from "../Documents/DocumentFamilySubscriptions/documentFamilySubscription.service";
import { DocumentFamilySubscription } from "../Documents/DocumentFamilySubscriptions/documentFamilySubscription";
import { SearchSubscription } from "../SearchSubscriptions/searchSubscription";
import { SearchSubscriptionService } from "../SearchSubscriptions/searchSubscription.service";

export function getSubscriptionType(
  subscription: Subscription
): SubscriptionType {
  if ((subscription as SearchSubscription).query !== undefined) {
    return SubscriptionType.Search;
  }
  if ((subscription as DocumentFamilySubscription).hiveId !== undefined) {
    return SubscriptionType.DocumentFamily;
  }
  if ((subscription as DocumentVersionSubscription).documentId !== undefined) {
    return SubscriptionType.DocumentVersion;
  }
  throw "Unknown subscription type";
}

export class SubscriptionsViewController {
  public subscriptions: Array<Subscription>;

  public verifiedEmails: string[];
  private allSubscriptionsTranslatedMessage: string;

  public static $inject = [
    "$translate",
    "currentUserService",
    "localizedNotificationService",
    "searchSubscriptionService",
    "documentVersionSubscriptionService",
    "documentFamilySubscriptionService"
  ];

  constructor(
    private $translate: angular.translate.ITranslateService,
    private currentUser: ICurrentUserService,
    private localizedNotificationService: ILocalizedNotificationService,
    private searchSubscriptionService: SearchSubscriptionService,
    private documentVersionSubscriptionService: DocumentVersionSubscriptionService,
    private documentFamilySubscriptionService: DocumentFamilySubscriptionService
  ) {}

  public async $onInit() {
    try {
      this.verifiedEmails = await this.currentUser.getVerifiedEmails();
    } catch {
      this.localizedNotificationService.error(
        "search_subscriptions.retrieve_email_error"
      );
    }

    try {
      const allSubscriptions = await Promise.all([
        this.currentUser.getSearchSubscriptions(),
        this.documentVersionSubscriptionService.getSubscriptionsForUser(),
        this.documentFamilySubscriptionService.getSubscriptionsForUser()
      ]);
      this.subscriptions = [
        ...allSubscriptions[0],
        ...allSubscriptions[1],
        ...allSubscriptions[2]
      ];
    } catch {
      this.localizedNotificationService.error(
        "search_subscriptions.retrieve_subscriptions_error"
      );
    }

    this.allSubscriptionsTranslatedMessage = await this.$translate(
      "search_subscriptions.all_news"
    );
  }

  public async update(
    subscription: Subscription,
    frequency?: SubscriptionFrequency,
    suspended: boolean = false
  ) {
    const oldFrequency = subscription.frequency;
    if (frequency) {
      subscription.frequency = frequency;
    }
    subscription.suspended = suspended;

    try {
      await this.updateSubscription(subscription);
      if (suspended)
        this.localizedNotificationService.success(
          "search_subscriptions.updated_success",
          { description: subscription.name }
        );
      else {
        this.localizedNotificationService.success(
          "search_subscriptions.subscription_update_success",
          { description: subscription.name }
        );
      }
    } catch {
      subscription.frequency = oldFrequency;
      this.localizedNotificationService.error(
        "search_subscriptions.subscription_update_error",
        { description: subscription.name }
      );
    }
  }

  public async updateAll(suspended: boolean) {
    for (const subscription of this.subscriptions) {
      if (suspended !== subscription.suspended) {
        await this.update(subscription, subscription.frequency, suspended);
      }
    }
  }

  public async delete(subscription: Subscription) {
    try {
      await this.deleteSubscription(subscription);
      subscription.deleted = true;
      this.localizedNotificationService.success(
        "search_subscriptions.delete_success",
        { description: subscription.name }
      );
    } catch {
      this.localizedNotificationService.error(
        "search_subscriptions.delete_error",
        { description: subscription.name }
      );
    }
  }

  public async restore(subscription: Subscription) {
    try {
      await this.updateSubscription(subscription);
      subscription.deleted = false;
      this.localizedNotificationService.success(
        "search_subscriptions.update_success",
        { description: subscription.name }
      );
    } catch {
      this.localizedNotificationService.error(
        "search_subscriptions.update_error",
        { description: subscription.name }
      );
    }
  }

  public async subscribeToAll() {
    const existingWithEmptyQuery = _(
      this.subscriptions
    ).find<SearchSubscription>((sub: SearchSubscription) => sub.query === null);
    if (existingWithEmptyQuery) {
      this.localizedNotificationService.error(
        "search_subscriptions.subscribe_to_all_error"
      );
      return;
    }

    const searchSubscription = {
      name: this.allSubscriptionsTranslatedMessage,
      emailAddress: this.verifiedEmails[0],
      frequency: SubscriptionFrequency.Weekly,
      query: null
    } as SearchSubscription;

    try {
      const subscription = await this.searchSubscriptionService.create(
        searchSubscription
      );
      this.localizedNotificationService.success(
        "search_subscriptions.create_success",
        { description: subscription.name }
      );
      this.subscriptions.unshift(subscription);
    } catch {
      this.localizedNotificationService.error(
        "search_subscriptions.create_error",
        { description: searchSubscription.name }
      );
    }
  }

  private async updateSubscription(subscription: Subscription) {
    switch (getSubscriptionType(subscription)) {
      case SubscriptionType.Search:
        await this.currentUser.updateSearchSubscription(
          subscription as SearchSubscription
        );
        break;
      case SubscriptionType.DocumentVersion:
        await this.documentVersionSubscriptionService.update(
          subscription as DocumentVersionSubscription
        );
        break;
      case SubscriptionType.DocumentFamily:
        await this.documentFamilySubscriptionService.update(
          subscription as DocumentFamilySubscription
        );
        break;
    }
  }

  private async deleteSubscription(subscription: Subscription) {
    switch (getSubscriptionType(subscription)) {
      case SubscriptionType.Search:
        await this.currentUser.deleteSearchSubscription(
          subscription.subscriptionId
        );
        break;

      case SubscriptionType.DocumentVersion:
        await this.documentVersionSubscriptionService.deleteById(
          subscription.subscriptionId
        );
        break;
      case SubscriptionType.DocumentFamily:
        await this.documentFamilySubscriptionService.deleteById(
          subscription.subscriptionId
        );
        break;
    }
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.SubscriptionsViewController",
    SubscriptionsViewController
  );
