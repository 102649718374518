import * as angular from "angular";
import * as _ from "underscore";
import { ITaxonomyState } from "./ITaxonomyState";
import { ITaxon } from "./SearchAssistent/Providers/ITaxon";
import { Taxonomy } from "./../Services/models/taxonomy";
import { TaxonomyToFlatTaxonListConverter } from "./TaxonomyToFlatTaxonListConverter";

export interface ITaxonomyStateService {
  getTaxonomies(): ReadonlyArray<ITaxonomyState>;
  addTaxonomy(providerKey: string, taxonomy: Taxonomy): void;
  removeTaxonomy(providerKey: string): void;
  clear(): void;
  getFlatTaxons(): ITaxon[];
  // state to determine initial taxonomy loading
  onInitialLoadCompleted: Rx.BehaviorSubject<boolean>;
  registerInitialTaxonomyLoadFinished(): void;
  registerComponentAsInitialTaxonomyLoader(): void;
}

export class TaxonomyStateService implements ITaxonomyStateService {
  private componentCount: number;
  private loadCount: number;

  public static $inject: string[] = [];

  /**
   * This observable enables tracking of when initial load of taxonomies has completed
   *
   * This can be used in scenarios where subsequent loading decisions needs to be taken
   *
   * It is based on components (angularjs components) registering themselves as "initial taxonomy loaders" immediately during $onInit phase
   * (or preferably constructor phase if possible) using method registerComponentAsInitialTaxomonyLoader()
   * and then when taxonomy has been loaded calling registerInitialTaxonomyLoadFinished()
   *
   * when all registered components have completed onInitialLoadCompleted will post a completion signal
   *
   * using BehaviourSubject so current value can be read
   * and subscribing to it after onNext is fired invokes last change in subscriber
   */
  public onInitialLoadCompleted = new Rx.BehaviorSubject<boolean>(false);

  constructor() {
    this.componentCount = 0;
    this.loadCount = 0;
  }

  private taxonomiesState: ReadonlyArray<ITaxonomyState> = [];
  private flatTaxons: ITaxon[] = [];

  public $onDestroy() {
    this.onInitialLoadCompleted.onCompleted();
  }

  // called from taxonomy controller constructors to count number of taxonomy components on the page
  public registerComponentAsInitialTaxonomyLoader() {
    this.componentCount++;
  }

  // called from taxonomy controller when taxonomy has been loaded
  public registerInitialTaxonomyLoadFinished() {
    this.loadCount++;

    // when all components registered as initial taxonomy loaders seems to have finished
    // we broadcast that initial load of all taxonomies deemed as "initial" has completed

    // this implementation of trying to count components involved in initial load, and have them register when they are finished loading taxonomy
    // and then other logic depend on this initial taxonomy load subscription is quite brittle and susceptible to hard to debug timing bugs
    // also it is very easy to introduce bugs if moving code around in taxonomy.controller, and not being aware of this timing
    // (and bugs wont necessarily been seen until some special combination of taxonomies on page, specific configuration and/or during deep link scenarios)
    // first it assumes that registerComponentAsInitialTaxonomyLoader and registerInitialTaxonomyLoadFinished is called in right order
    // (all registerComponentAsInitialTaxonomyLoader potentially occuring during pageload is expected to happen before any calls to registerInitialTaxonomyLoadFinished )
    // and currently registerComponentAsInitialTaxonomyLoader is called during $onInit phase of taxonomy.controller and not constructor
    // Since $onChange fires before $onInit and it seems loading could start in this phase in some cases, this could potentially result in weird bugs
    // It is also unclear if it is desired that onInitialLoadCompleted should fire several times, since it could happen if some taxonomy controllers
    // would be loaded dynamically as result of data load. Not sure if logic depending onInitialLoadCompleted can handle that without undesired behavior
    // In general, reconsider this implementation if more bugs come in, and reconsider taxonomyStateService/productTaxonomy component coordination    //
    if (this.componentCount !== 0 && this.loadCount === this.componentCount) {
      this.onInitialLoadCompleted.onNext(true);
    }
  }

  public getTaxonomies(): ReadonlyArray<ITaxonomyState> {
    return this.taxonomiesState;
  }

  public addTaxonomy(criteriaProviderKey: string, taxonomy: Taxonomy): void {
    let taxonomiesState = this.taxonomiesState;

    const newTaxonomyState = {
      criteriaProviderKey,
      taxonomyName: taxonomy.name,
      flatTaxons: new TaxonomyToFlatTaxonListConverter().buildTaxonsFlatList(
        taxonomy
      )
    };

    taxonomiesState = [...taxonomiesState, newTaxonomyState];
    this.taxonomiesState = taxonomiesState;
    this.flatTaxons = this.createFlatTaxonsArray();
  }

  public removeTaxonomy(providerKey: string): void {
    let taxonomyStates: ReadonlyArray<ITaxonomyState> = [];

    let itemsRemoved = 0;
    this.taxonomiesState.forEach(state => {
      if (state.criteriaProviderKey !== providerKey) {
        taxonomyStates = [...taxonomyStates, state];
      } else {
        if (itemsRemoved > 0) {
          taxonomyStates = [...taxonomyStates, state];
        }
        itemsRemoved++;
      }
    });

    this.taxonomiesState = taxonomyStates;
    this.flatTaxons = this.createFlatTaxonsArray();
  }

  public clear(): void {
    this.taxonomiesState = [];
    this.flatTaxons = [];
    this.componentCount = 0;
    this.loadCount = 0;
  }

  public getFlatTaxons(): ITaxon[] {
    return this.flatTaxons;
  }

  private createFlatTaxonsArray(): ITaxon[] {
    const flatTaxons: ITaxon[] = [];

    this.taxonomiesState.map(taxonomyState => {
      taxonomyState.flatTaxons.forEach(taxon => {
        flatTaxons.push({
          taxonomyName: taxonomyState.taxonomyName,
          translatedTaxonomyName: taxonomyState.taxonomyName,
          criteriaProviderKey: taxonomyState.criteriaProviderKey,
          taxonKey: taxon.key,
          taxonTitle: taxon.title,
          taxonLongTitle: taxon.longTitle
        });
      });
    });

    return flatTaxons;
  }
}

angular
  .module("PortalApp")
  .service("taxonomyStateService", TaxonomyStateService);
