import * as angular from "angular";
import { ILocalizedNotificationService } from "../Services/localized.notification.service";
import { DocumentVersionSubscriptionService } from "../Documents/DocumentVersionSubscriptions/documentVersionSubscription.service";
import { DocumentVersionSubscription } from "../Documents/DocumentVersionSubscriptions/documentVersionSubscription";
import { DocumentFamilySubscriptionService } from "../Documents/DocumentFamilySubscriptions/documentFamilySubscription.service";
import { DocumentFamilySubscription } from "../Documents/DocumentFamilySubscriptions/documentFamilySubscription";
import {
  Subscription,
  SubscriptionFrequency,
  SubscriptionType
} from "../Subscriptions/subscription";
import { getSubscriptionType } from "../Subscriptions/subscriptionsView.controller";
import { SearchSubscriptionService } from "../SearchSubscriptions/searchSubscription.service";
import { SearchSubscription } from "../SearchSubscriptions/searchSubscription";

export class UnsubscribeSubscriptionsViewController {
  public email: string;
  private ticket: string;

  public subscriptions: Array<Subscription>;

  public static $inject = [
    "$routeParams",
    "$scope",
    "localizedNotificationService",
    "searchSubscriptionService",
    "documentVersionSubscriptionService",
    "documentFamilySubscriptionService"
  ];

  constructor(
    $routeParams: any,
    private $scope: angular.IScope,
    private localizedNotificationService: ILocalizedNotificationService,
    private searchSubscriptionService: SearchSubscriptionService,
    private documentVersionSubscriptionService: DocumentVersionSubscriptionService,
    private documentFamilySubscriptionService: DocumentFamilySubscriptionService
  ) {
    this.email = $routeParams.email;
    this.ticket = $routeParams.ticket;
  }

  public async $onInit() {
    try {
      const allSubscriptions = await Promise.all([
        this.searchSubscriptionService.getSearchSubscriptionsByEmail(
          this.email,
          this.ticket
        ),
        this.documentVersionSubscriptionService.getSubscriptionsByEmail(
          this.email,
          this.ticket
        ),
        this.documentFamilySubscriptionService.getSubscriptionsByEmail(
          this.email,
          this.ticket
        )
      ]);
      this.subscriptions = [
        ...allSubscriptions[0],
        ...allSubscriptions[1],
        ...allSubscriptions[2]
      ];
      this.$scope.$apply();
    } catch (error) {
      this.localizedNotificationService.error(
        "search_subscriptions.retrieve_error"
      );
    }
  }

  public async update(
    subscription: Subscription,
    frequency?: SubscriptionFrequency,
    suspended: boolean = false
  ) {
    const oldFrequency = subscription.frequency;
    if (frequency) {
      subscription.frequency = frequency;
    }
    subscription.suspended = suspended;

    try {
      switch (getSubscriptionType(subscription)) {
        case SubscriptionType.Search:
          await this.searchSubscriptionService.update(
            subscription as SearchSubscription,
            this.email,
            this.ticket
          );
          break;
        case SubscriptionType.DocumentVersion:
          await this.documentVersionSubscriptionService.updateUsingTicket(
            subscription as DocumentVersionSubscription,
            this.email,
            this.ticket
          );
          break;
        case SubscriptionType.DocumentFamily:
          await this.documentFamilySubscriptionService.updateUsingTicket(
            subscription as DocumentFamilySubscription,
            this.email,
            this.ticket
          );
      }

      this.localizedNotificationService.success(
        "search_subscriptions.subscription_update_success",
        { description: subscription.name }
      );
    } catch (error) {
      subscription.frequency = oldFrequency;
      this.localizedNotificationService.error(
        "search_subscriptions.subscription_update_error",
        { description: subscription.name }
      );
    }
  }

  public async delete(subscription: Subscription) {
    try {
      switch (getSubscriptionType(subscription)) {
        case SubscriptionType.Search:
          await this.searchSubscriptionService.delete(
            subscription.subscriptionId,
            this.email,
            this.ticket
          );
          break;
        case SubscriptionType.DocumentVersion:
          await this.documentVersionSubscriptionService.deleteByIdUsingTicket(
            subscription.subscriptionId,
            this.email,
            this.ticket
          );
          break;
        case SubscriptionType.DocumentFamily:
          await this.documentFamilySubscriptionService.deleteByIdUsingTicket(
            subscription.subscriptionId,
            this.email,
            this.ticket
          );
          break;
      }
      subscription.deleted = true;
      this.localizedNotificationService.success(
        "search_subscriptions.subscription_delete_success",
        { description: subscription.name }
      );
    } catch (error) {
      this.localizedNotificationService.error(
        "search_subscriptions.subscription_delete_error",
        { description: subscription.name }
      );
    }
  }

  public async restore(subscription: Subscription) {
    try {
      switch (getSubscriptionType(subscription)) {
        case SubscriptionType.Search:
          await this.searchSubscriptionService.update(
            subscription as SearchSubscription,
            this.email,
            this.ticket
          );
          break;
        case SubscriptionType.DocumentVersion:
          await this.documentVersionSubscriptionService.updateUsingTicket(
            subscription as DocumentVersionSubscription,
            this.email,
            this.ticket
          );
          break;
        case SubscriptionType.DocumentFamily:
          await this.documentFamilySubscriptionService.updateUsingTicket(
            subscription as DocumentFamilySubscription,
            this.email,
            this.ticket
          );
          break;
      }
      subscription.deleted = false;
      this.localizedNotificationService.success(
        "search_subscriptions.subscription_restore_success",
        { description: subscription.name }
      );
    } catch (error) {
      this.localizedNotificationService.error(
        "search_subscriptions.subscription_restore_error",
        { description: subscription.name }
      );
    }
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.UnsubscribeSubscriptionsViewController",
    UnsubscribeSubscriptionsViewController
  );
