import * as angular from "angular";
import { PortalDataHttpService } from "../../Services/DataService/PortalDataHttpService";
import { DocumentFamilySubscription } from "./documentFamilySubscription";

export class DocumentFamilySubscriptionService {
  public static $inject = ["portalDataService"];

  constructor(private portalDataService: PortalDataHttpService) {}

  public async update(
    subscription: DocumentFamilySubscription
  ): Promise<DocumentFamilySubscription> {
    return await this.portalDataService.put<DocumentFamilySubscription>(
      "User/DocumentFamilySubscription/",
      {
        frequency: subscription.frequency,
        suspended: subscription.suspended,
        emailAddress: subscription.emailAddress,
        subscriptionId: subscription.subscriptionId
      }
    );
  }

  public async updateUsingTicket(
    subscription: DocumentFamilySubscription,
    email: string,
    ticket: string
  ): Promise<DocumentFamilySubscription> {
    return await this.portalDataService.put<DocumentFamilySubscription>(
      `EmailAccounts(${email})/DocumentFamilySubscription?ticket=${ticket}`,
      {
        frequency: subscription.frequency,
        suspended: subscription.suspended,
        subscriptionId: subscription.subscriptionId
      }
    );
  }

  public async deleteById(subscriptionId: string): Promise<{}> {
    return await this.portalDataService.delete(
      `User/DocumentFamilySubscription/${subscriptionId}`
    );
  }

  public async deleteByIdUsingTicket(
    subscriptionId: string,
    email: string,
    ticket: string
  ): Promise<{}> {
    return await this.portalDataService.delete(
      `EmailAccounts(${email})/DocumentFamilySubscription/${subscriptionId}?ticket=${ticket}`
    );
  }

  public async getSubscriptionsForUser(): Promise<
    DocumentFamilySubscription[]
  > {
    return this.confirmAll(
      await this.portalDataService.get<DocumentFamilySubscription[]>(
        "User/DocumentFamilySubscriptions/"
      )
    );
  }

  public async getSubscriptionsByEmail(
    email: string,
    ticket: string
  ): Promise<DocumentFamilySubscription[]> {
    const url = `EmailAccounts(${email})/DocumentFamilySubscriptions?ticket=${ticket}`;
    return this.confirmAll(
      await this.portalDataService.get<DocumentFamilySubscription[]>(url)
    );
  }

  private confirmAll(
    subscriptions: DocumentFamilySubscription[]
  ): DocumentFamilySubscription[] {
    subscriptions.forEach(s => {
      s.confirmed = true;
    });
    return subscriptions;
  }
}

angular
  .module("PortalApp")
  .service(
    "documentFamilySubscriptionService",
    DocumentFamilySubscriptionService
  );
