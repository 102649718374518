import * as angular from "angular";
import { SpaConfigDto } from "@rhinestone/portal-web-api";

export async function initializeApplication(rhinestoneConfig: SpaConfigDto) {
  document.title = rhinestoneConfig.title;

  insertPortalCustomCssStylesheet(rhinestoneConfig);

  if (rhinestoneConfig.googleTagManagerId) {
    initGoogleTagManager(
      window,
      document,
      "script",
      "#gtm-anchor",
      "dataLayer",
      rhinestoneConfig.googleTagManagerId
    );
  }
  angular.module("PortalApp").constant("rhinestoneConfig", rhinestoneConfig);
  angular.bootstrap(document, ["PortalApp"]);
}

export function insertPortalCustomCssStylesheet(
  rhinestoneConfig: SpaConfigDto
) {
  const link = document.createElement("link");
  link.type = "text/css";
  link.rel = "stylesheet";
  link.href = `/api/Portals(${rhinestoneConfig.identifier})/Css?version=${rhinestoneConfig.lastModifiedTag}`;
  document.head.appendChild(link);
}

/**
 * Ported from google tag manager code samples from the www
 *
 */
export function initGoogleTagManager(
  w: any,
  d: any,
  s: any,
  p: string,
  l: any,
  i: any
) {
  w[l] = w[l] || [];
  w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
  const f = d.querySelector(p),
    j = d.createElement(s),
    dl = l != "dataLayer" ? "&l=" + l : "";
  j.async = true;
  j.src = "/gtm.js?id=" + i + dl;

  if (f === undefined)
    throw Error(
      "Couldn't find place to insert google tag manager script. " +
        `Expecting a script tag with id '${p}' on page.` +
        "Please make sure to add this or change this code"
    );
  f.parentNode.insertBefore(j, f);
}

/**
 *
 * @param error the error object from fetch catch block
 */
export function bootStrapErrorHandler(error: Error) {
  const errorBlock = document.createElement("div");
  errorBlock.textContent = `Unable to get configuration. Can't start application. Contact support.`;

  if (process.env.NODE_ENV === "development") {
    const devMessage = document.createElement("div");
    devMessage.setAttribute("style", "margin-top: 20px");
    devMessage.textContent =
      "Dear developer: Unable to get bootstrap data from endpoint '/bootstrap', or subsequent initialization of SPA failed. " +
      "Please check console, network traffic and app.run.ts, and investigate why endpoint doesn't return expected data. See exception below:";
    const exceptionBlock = document.createElement("div");
    exceptionBlock.setAttribute("style", "margin-top: 20px");
    exceptionBlock.textContent = error.message;
    devMessage.appendChild(exceptionBlock);
    errorBlock.appendChild(devMessage);
  }

  document.body.insertBefore(errorBlock, document.body.firstElementChild);
}
