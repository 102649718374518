import * as angular from "angular";
import {
  Subscription,
  SubscriptionFrequency,
  SubscriptionType
} from "../Subscriptions/subscription";
import { getSubscriptionType } from "../Subscriptions/subscriptionsView.controller";

export class SubscriptionsListController {
  // Input binding
  public subscriptions: Array<Subscription>;

  // Output bindings
  public onSubscriptionUpdate: ({
    subscription,
    frequency,
    suspended
  }: {
    subscription: Subscription;
    frequency: SubscriptionFrequency;
    suspended: boolean;
  }) => void;
  public onSubscriptionDelete: ({
    subscription
  }: {
    subscription: Subscription;
  }) => void;
  public onSubscriptionRestore: ({
    subscription
  }: {
    subscription: Subscription;
  }) => void;

  public static $inject: string[] = [];

  constructor() {}

  public async $onInit() {}

  public isSearchSubscription(subscription: Subscription): boolean {
    return getSubscriptionType(subscription) === SubscriptionType.Search;
  }

  public isDocumentVersionSubscription(subscription: Subscription): boolean {
    return (
      getSubscriptionType(subscription) === SubscriptionType.DocumentVersion
    );
  }

  public isDocumentFamilySubscription(subscription: Subscription): boolean {
    return (
      getSubscriptionType(subscription) === SubscriptionType.DocumentFamily
    );
  }

  public update(
    subscription: Subscription,
    frequency: SubscriptionFrequency,
    suspended: boolean
  ) {
    this.onSubscriptionUpdate({ subscription, frequency, suspended });
  }

  public delete(subscription: Subscription) {
    this.onSubscriptionDelete({ subscription });
  }

  public restore(subscription: Subscription) {
    this.onSubscriptionRestore({ subscription });
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.SubscriptionsListController",
    SubscriptionsListController
  );
